import type { ReactElement } from 'react'

import { GPSRInformation } from '../epages.base/GPSRInformation'
import { PriceStructuredData, getProductMicrodata } from '../epages.base/ProductPrice'
import { ProductDetailProvider } from '../../productDetails/ProductDetailProvider'
import Breadcrumb from '../epages.base/Breadcrumb'
import BulkPrices from '../epages.base/BulkPrices'
import Buybox from '../../productDetails/Buybox'
import BuyboxThemeComponent from '../epages.base/Buybox'
import Crossselling from '../epages.base/Crossselling'
import CustomAttributes from '../epages.base/CustomAttributes'
import EnergyLabel from '../../templateComponents/EnergyLabel'
import Link from '../../templateComponents/Link'
import Percentage from '../../productDetails/Percentage'
import Price from '../../productDetails/Price'
import PriceReference from '../../productDetails/PriceReference'
import ProductDescription from '../epages.base/ProductDescription'
import ProductImage from '../epages.base/ProductImage'
import ProductImageGallery from '../epages.base/ProductImageGallery'

export default function Product({ product, shop, breadcrumb, t }: ProductThemeComponentProps): ReactElement {
  return (
    <div className="product" {...getProductMicrodata(product)}>
      {product.sku && <meta itemProp="sku" content={product.sku} />}
      {product.gtin && <meta itemProp={`gtin${product.gtin.length}`} content={product.gtin} />}
      {product.manufacturer && <meta itemProp="brand" content={product.manufacturer} />}
      {product.productVariationSelection?.map((variation) => (
        <div key={variation.value} itemProp="additionalProperty" itemScope itemType="https://schema.org/PropertyValue">
          <meta itemProp="name" content={variation.name} />
          <meta itemProp="value" content={variation.displayValue} />
        </div>
      ))}
      <section className="section">
        <div className="wrapper">
          <Breadcrumb breadcrumb={breadcrumb} />
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <div className="content">
            {product.slideshow ? (
              <div className="product-image-gallery-wrapper">
                <ProductImageGallery product={product} productLabels={product.labels} />
              </div>
            ) : (
              <ProductImage image={product.image} alt={product.name} productLabels={product.labels} />
            )}

            <ProductDetailProvider productId={product.productId} minQuantity={product.orderUnitInfo.minOrder}>
              <div className="product-info">
                <h1 className="product-info-title" itemProp="name">
                  {product.name}
                </h1>
                <div id="product-info-top-content" />
                {product.isProductNumberVisible && (
                  <p className="product-info-sku">
                    {t('components.productComponent.productNumber', {
                      sku: product.sku,
                    })}
                  </p>
                )}
                {(product.price || product.lowestPrice) && (
                  <div className="product-info-box">
                    <PriceStructuredData product={product} />
                    {product.strikePrice && !product.hasVariations && product.price && (
                      <div className="product-info-box-price-old">
                        {product.isStrikePriceRRP && (
                          <span className="product-info-box-price-manufacturer">
                            {t('components.productComponent.priceManufacturer')}
                          </span>
                        )}
                        <span>{product.strikePrice.formatted}</span>
                        <Percentage
                          strikePriceAmount={product.strikePrice.amount}
                          priceAmount={product.price.amount}
                          bulkPrices={product.bulkPrices}
                          className="product-info-box-price-percentage"
                        />
                      </div>
                    )}
                    {!product.lowestPrice && (
                      <Price
                        className={product.strikePrice ? 'product-info-box-price-new' : 'product-info-box-price-single'}
                        product={product}
                      />
                    )}
                    {product.lowestPrice && (
                      <div className="product-info-box-price-single">
                        {t('components.productComponent.variationLowestPrice', {
                          amount: product.lowestPrice.formatted,
                        })}
                      </div>
                    )}
                    {product.basePrice && (
                      <PriceReference
                        lowestRefPriceFormatted={product.lowestRefPriceFormatted}
                        basePriceFormatted={product.basePrice.formatted}
                        bulkPrices={product.bulkPrices}
                        className="product-info-box-price-reference"
                      />
                    )}
                    <div className="product-info-box-wrapper">
                      <div className="product-info-box-vat-note">
                        {product.vatNote && t(product.vatNote, { nsSeparator: '__' })}
                        {product.vatNote && product.isShippingLinkVisible && ', '}
                        {product.isShippingLinkVisible && (
                          <Link className="product-info-box-shipping-link" to={shop.legalPageUrls.shipping}>
                            {t('components.productComponent.plusShipping')}
                          </Link>
                        )}
                        {product.deposit && (
                          <>
                            {product.vatNote || product.isShippingLinkVisible ? ', ' : null}
                            {t('components.productComponent.deposit', {
                              amount: product.deposit.formatted,
                            })}
                          </>
                        )}
                      </div>
                      {shop.beyond && product.weight && (
                        <div className="product-info-box-product-weight">
                          {t('components.productComponent.productWeight', { weight: product.weight.formatted })}
                        </div>
                      )}
                    </div>
                    <div id="ep-app-area-product-view-price"></div>
                    {product.energyLabel && (
                      <EnergyLabel
                        t={t}
                        energyLabel={product.energyLabel}
                        energyLabelSourceFile={product.energyLabelSourceFile}
                      />
                    )}
                    {product.productDataSheet && (
                      <a
                        href={product.productDataSheet.dataUri}
                        target="_blank"
                        rel="noreferrer"
                        className="product-info-box-product-data-sheet"
                      >
                        <i className="fa fa-file-text-o"></i>
                        {product.productDataSheet.label}
                      </a>
                    )}
                  </div>
                )}

                <Buybox product={product}>
                  <BuyboxThemeComponent t={t} product={product} shop={shop} />
                </Buybox>

                <hr />
                <ProductDescription
                  description={product.description}
                  additionalDescriptions={product.additionalDescriptions}
                  t={t}
                />
                <CustomAttributes customAttributes={product.customAttributes} t={t} />
                <BulkPrices product={product} t={t} />
                <GPSRInformation gpsrInfo={product.gpsrInfo} t={t} />
                <div id="product-info-bottom-content" />
              </div>
            </ProductDetailProvider>
          </div>
          {product.hasCrossSelling && <Crossselling product={product} />}
          <div id="product-footer-content" />
        </div>
      </section>
    </div>
  )
}
