import type { ReactElement } from 'react'

import CrossSellingBar from '../../templateComponents/CrossSellingBar'
import EmptyProductListItem from '../../templateComponents/EmptyProductListItem'
import ProductListItem from '../../templateComponents/ProductListItem'

type Props = {
  product: Frontend.Product
}

export default function Crossselling({ product }: Readonly<Props>): ReactElement {
  return (
    <CrossSellingBar
      product={product}
      breakpoints={['480px', '782px', '992px']}
      renderProductItem={(product, productIndex, trackProductClick) => (
        <ProductListItem
          product={product}
          productIndex={productIndex}
          trackProductClick={trackProductClick}
          withMicrodata={false}
        />
      )}
      renderEmptyProductItem={() => <EmptyProductListItem />}
    />
  )
}
